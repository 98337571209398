<template>
    <div>
        <div class="block-category-title">Stacked Layout</div>
        <BlockViewer header="Branded Navigation" :code="block1">
            <div class="min-h-screen flex flex-column surface-ground">
                <div class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
                    <img src="images/blocks/logos/bastion-700.svg" alt="Image" height="40" class="mr-0 lg:mr-6" />
                    <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                        <i class="pi pi-bars text-4xl"></i>
                    </a>
                    <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
                        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-home mr-2"></i>
                                    <span>Home</span>
                                </a>
                            </li>
                            <li class="lg:relative">
                                <a
                                    v-ripple
                                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                >
                                    <i class="pi pi-users mr-2"></i>
                                    <span>Customers</span>
                                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                                </a>
                                <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                                    <li>
                                        <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-user-plus mr-2"></i>
                                            <span class="font-medium">Add New</span>
                                        </a>
                                    </li>
                                    <li class="relative">
                                        <a
                                            v-ripple
                                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                        >
                                            <i class="pi pi-search mr-2"></i>
                                            <span class="font-medium">Search</span>
                                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                        </a>
                                        <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                            <li>
                                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-shopping-cart mr-2"></i>
                                                    <span class="font-medium">Purchases</span>
                                                </a>
                                            </li>
                                            <li class="relative">
                                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-comments mr-2"></i>
                                                    <span class="font-medium">Messages</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-calendar mr-2"></i>
                                    <span>Calendar</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-chart-line mr-2"></i>
                                    <span>Stats</span>
                                </a>
                            </li>
                        </ul>
                        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                    <span class="block lg:hidden font-medium">Inbox</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
                                    <span class="block lg:hidden font-medium">Notifications</span>
                                </a>
                            </li>
                            <li class="border-top-1 surface-border lg:border-top-none">
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                                    <div class="block lg:hidden">
                                        <div class="text-900 font-medium">Josephine Lillard</div>
                                        <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
                    <li class="pr-3">
                        <a class="cursor-pointer">
                            <i class="pi pi-home text-blue-500"></i>
                        </a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
                    </li>
                </ul>
                <div class="p-5 flex flex-column flex-auto">
                    <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Dark TopBar" :code="block2">
            <div class="min-h-screen flex flex-column surface-ground">
                <div class="bg-gray-900 py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static border-bottom-1 border-gray-800" style="min-height: 84px">
                    <img src="images/blocks/logos/bastion-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6" />
                    <a v-ripple class="cursor-pointer block lg:hidden text-gray-400 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                        <i class="pi pi-bars text-4xl"></i>
                    </a>
                    <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-home mr-2"></i>
                                    <span>Home</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    v-ripple
                                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                >
                                    <i class="pi pi-users mr-2"></i>
                                    <span>Customers</span>
                                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                                </a>
                                <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                                    <li>
                                        <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-user-plus mr-2"></i>
                                            <span class="font-medium">Add New</span>
                                        </a>
                                    </li>
                                    <li class="relative">
                                        <a
                                            v-ripple
                                            class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple"
                                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                        >
                                            <i class="pi pi-search mr-2"></i>
                                            <span class="font-medium">Search</span>
                                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                        </a>
                                        <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800">
                                            <li>
                                                <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-shopping-cart mr-2"></i>
                                                    <span class="font-medium">Purchases</span>
                                                </a>
                                            </li>
                                            <li class="relative">
                                                <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-comments mr-2"></i>
                                                    <span class="font-medium">Messages</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-calendar mr-2"></i>
                                    <span>Calendar</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-chart-line mr-2"></i>
                                    <span>Stats</span>
                                </a>
                            </li>
                        </ul>
                        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                            <li class="flex-order-2 lg:flex-order-0">
                                <div class="flex px-6 p-3 lg:px-3 lg:py-2">
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-search text-gray-400"></i>
                                        <InputText type="text" placeholder="Search" class="bg-gray-800 text-gray-400 border-gray-800 w-full" />
                                    </span>
                                </div>
                            </li>
                            <li class="border-top-1 border-gray-800 lg:border-top-none">
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                                    <div class="block lg:hidden">
                                        <div class="text-white font-medium">Josephine Lillard</div>
                                        <span class="text-gray-400 font-medium text-sm">Marketing Specialist</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
                    <li class="pr-3">
                        <a class="cursor-pointer">
                            <i class="pi pi-home text-blue-500"></i>
                        </a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
                    </li>
                </ul>
                <div class="p-5 flex flex-column flex-auto">
                    <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Colored TopBar" :code="block3">
            <div class="min-h-screen flex flex-column">
                <div class="bg-indigo-500 py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
                    <img src="images/blocks/logos/hyper-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6" />
                    <a v-ripple class="cursor-pointer block lg:hidden text-indigo-50 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                        <i class="pi pi-bars text-4xl"></i>
                    </a>
                    <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1 shadow-2 lg:shadow-none">
                        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-home mr-2"></i>
                                    <span>Home</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    v-ripple
                                    class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                >
                                    <i class="pi pi-users mr-2"></i>
                                    <span>Customers</span>
                                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                                </a>
                                <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                                    <li>
                                        <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-user-plus mr-2"></i>
                                            <span class="font-medium">Add New</span>
                                        </a>
                                    </li>
                                    <li class="relative">
                                        <a
                                            v-ripple
                                            class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                        >
                                            <i class="pi pi-search mr-2"></i>
                                            <span class="font-medium">Search</span>
                                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                        </a>
                                        <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                            <li>
                                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-shopping-cart mr-2"></i>
                                                    <span class="font-medium">Purchases</span>
                                                </a>
                                            </li>
                                            <li class="relative">
                                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-comments mr-2"></i>
                                                    <span class="font-medium">Messages</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-calendar mr-2"></i>
                                    <span>Calendar</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-chart-line mr-2"></i>
                                    <span>Stats</span>
                                </a>
                            </li>
                        </ul>
                        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                    <span class="block lg:hidden font-medium">Inbox</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                    <span class="block lg:hidden font-medium">Notifications</span>
                                </a>
                            </li>
                            <li class="border-top-1 border-indigo-400 lg:border-top-none">
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                                    <div class="block lg:hidden">
                                        <div class="text-indigo-50 font-medium">Josephine Lillard</div>
                                        <span class="text-indigo-100 font-medium text-sm">Marketing Specialist</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border shadow-2 lg:shadow-none">
                    <li class="pr-3">
                        <a class="cursor-pointer">
                            <i class="pi pi-home text-blue-500"></i>
                        </a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
                    </li>
                </ul>
                <div class="p-5 flex flex-column flex-auto">
                    <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Colored with Overlap" :code="block4">
            <div class="min-h-screen flex flex-column">
                <div class="bg-indigo-500" style="height: 250px">
                    <div class="py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
                        <img src="images/blocks/logos/hyper-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6" />
                        <a v-ripple class="cursor-pointer block lg:hidden text-indigo-50 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                            <i class="pi pi-bars text-4xl"></i>
                        </a>
                        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1">
                            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                <li>
                                    <a
                                        v-ripple
                                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    >
                                        <i class="pi pi-home mr-2"></i>
                                        <span>Home</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        v-ripple
                                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                        v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                    >
                                        <i class="pi pi-users mr-2"></i>
                                        <span>Customers</span>
                                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                                    </a>
                                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                                        <li>
                                            <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                                <i class="pi pi-user-plus mr-2"></i>
                                                <span class="font-medium">Add New</span>
                                            </a>
                                        </li>
                                        <li class="relative">
                                            <a
                                                v-ripple
                                                class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                                                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                            >
                                                <i class="pi pi-search mr-2"></i>
                                                <span class="font-medium">Search</span>
                                                <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                            </a>
                                            <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                                <li>
                                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                                        <i class="pi pi-shopping-cart mr-2"></i>
                                                        <span class="font-medium">Purchases</span>
                                                    </a>
                                                </li>
                                                <li class="relative">
                                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                                        <i class="pi pi-comments mr-2"></i>
                                                        <span class="font-medium">Messages</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a
                                        v-ripple
                                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    >
                                        <i class="pi pi-calendar mr-2"></i>
                                        <span>Calendar</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        v-ripple
                                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    >
                                        <i class="pi pi-chart-line mr-2"></i>
                                        <span>Stats</span>
                                    </a>
                                </li>
                            </ul>
                            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
                                <li>
                                    <a
                                        v-ripple
                                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    >
                                        <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                        <span class="block lg:hidden font-medium">Inbox</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        v-ripple
                                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    >
                                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                        <span class="block lg:hidden font-medium">Notifications</span>
                                    </a>
                                </li>
                                <li class="border-top-1 border-indigo-400 lg:border-top-none">
                                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                        <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                                        <div class="block lg:hidden">
                                            <div class="text-indigo-50 font-medium">Josephine Lillard</div>
                                            <span class="text-indigo-100 font-medium text-sm">Marketing Specialist</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul class="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-indigo-600">
                        <li class="pr-3">
                            <a class="cursor-pointer">
                                <i class="pi pi-home text-indigo-200"></i>
                            </a>
                        </li>
                        <li class="px-2">
                            <i class="pi pi-angle-right text-indigo-200"></i>
                        </li>
                        <li class="px-2">
                            <a class="cursor-pointer text-indigo-200 white-space-nowrap">Level 3</a>
                        </li>
                        <li class="px-2">
                            <i class="pi pi-angle-right text-indigo-100"></i>
                        </li>
                        <li class="px-2">
                            <a class="cursor-pointer text-indigo-200 white-space-nowrap">Level 2</a>
                        </li>
                        <li class="px-2">
                            <i class="pi pi-angle-right text-indigo-200"></i>
                        </li>
                        <li class="px-2">
                            <a class="cursor-pointer text-indigo-100 white-space-nowrap">Level 1</a>
                        </li>
                    </ul>
                </div>
                <div class="p-5 flex flex-column flex-auto" style="margin-top: -8rem">
                    <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Dark with Overlap" :code="block5">
            <div class="min-h-screen flex flex-column">
                <div class="bg-gray-900" style="height: 250px">
                    <div class="py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
                        <img src="images/blocks/logos/hyper-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6" />
                        <a v-ripple class="cursor-pointer block lg:hidden text-gray-400 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                            <i class="pi pi-bars text-4xl"></i>
                        </a>
                        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1">
                            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                <li>
                                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-home mr-2"></i>
                                        <span>Home</span>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        v-ripple
                                        class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                                        v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                    >
                                        <i class="pi pi-users mr-2"></i>
                                        <span>Customers</span>
                                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                                    </a>
                                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                                        <li>
                                            <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                                <i class="pi pi-user-plus mr-2"></i>
                                                <span class="font-medium">Add New</span>
                                            </a>
                                        </li>
                                        <li class="relative">
                                            <a
                                                v-ripple
                                                class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple"
                                                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                            >
                                                <i class="pi pi-search mr-2"></i>
                                                <span class="font-medium">Search</span>
                                                <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                            </a>
                                            <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800">
                                                <li>
                                                    <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                                        <i class="pi pi-shopping-cart mr-2"></i>
                                                        <span class="font-medium">Purchases</span>
                                                    </a>
                                                </li>
                                                <li class="relative">
                                                    <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                                        <i class="pi pi-comments mr-2"></i>
                                                        <span class="font-medium">Messages</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-calendar mr-2"></i>
                                        <span>Calendar</span>
                                    </a>
                                </li>
                                <li>
                                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-chart-line mr-2"></i>
                                        <span>Stats</span>
                                    </a>
                                </li>
                            </ul>
                            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-gray-800 lg:border-top-none">
                                <li>
                                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                        <span class="block lg:hidden font-medium">Inbox</span>
                                    </a>
                                </li>
                                <li>
                                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                        <span class="block lg:hidden font-medium">Notifications</span>
                                    </a>
                                </li>
                                <li class="border-top-1 border-gray-800 lg:border-top-none">
                                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                        <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                                        <div class="block lg:hidden">
                                            <div class="text-white font-medium">Josephine Lillard</div>
                                            <span class="text-gray-400 font-medium text-sm">Marketing Specialist</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <ul class="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-gray-800">
                        <li class="pr-3">
                            <a class="cursor-pointer">
                                <i class="pi pi-home text-gray-400"></i>
                            </a>
                        </li>
                        <li class="px-2">
                            <i class="pi pi-angle-right text-gray-400"></i>
                        </li>
                        <li class="px-2">
                            <a class="cursor-pointer text-gray-400 white-space-nowrap">Level 3</a>
                        </li>
                        <li class="px-2">
                            <i class="pi pi-angle-right text-gray-400"></i>
                        </li>
                        <li class="px-2">
                            <a class="cursor-pointer text-gray-400 white-space-nowrap">Level 2</a>
                        </li>
                        <li class="px-2">
                            <i class="pi pi-angle-right text-gray-400"></i>
                        </li>
                        <li class="px-2">
                            <a class="cursor-pointer text-indigo-300 white-space-nowrap">Level 1</a>
                        </li>
                    </ul>
                </div>
                <div class="p-5 flex flex-column flex-auto" style="margin-top: -8rem">
                    <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Tabs" :code="block6" recent>
            <div class="min-h-screen flex flex-column surface-ground">
                <div class="surface-section pl-6 flex justify-content-between relative lg:static" style="min-height: 70px">
                    <img src="images/blocks/logos/hyper-700.svg" alt="Image" height="40" class="mr-0 lg:mr-6 align-self-center" />
                    <div class="cursor-pointer block lg:hidden align-self-center p-ripple text-700 pr-6" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                        <a v-ripple class="p-ripple">
                            <i class="pi pi-bars text-4xl"></i>
                        </a>
                    </div>
                    <div class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none surface-overlay">
                        <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row gap-2">
                            <li class="lg:flex lg:align-items-end">
                                <a
                                    v-ripple
                                    class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    :class="{ 'surface-ground text-900': activeTab === 0, 'hover:surface-hover': activeTab !== 0 }"
                                    @click="activeTab = 0"
                                >
                                    <i class="pi pi-home mr-2"></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li class="lg:flex lg:align-items-end">
                                <a
                                    v-ripple
                                    class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 text-900 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    :class="{ 'surface-ground text-900': activeTab === 1, 'hover:surface-hover': activeTab !== 1 }"
                                    @click="activeTab = 1"
                                >
                                    <i class="pi pi-chart-line mr-2"></i>
                                    <span>Reports</span>
                                </a>
                            </li>
                            <li class="lg:flex lg:align-items-end">
                                <a
                                    v-ripple
                                    class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 text-900 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    :class="{ 'surface-ground text-900': activeTab === 2, 'hover:surface-hover': activeTab !== 2 }"
                                    @click="activeTab = 2"
                                >
                                    <i class="pi pi-users mr-2"></i>
                                    <span class="mr-2">Team</span>
                                </a>
                            </li>
                            <li class="lg:flex lg:align-items-end">
                                <a
                                    v-ripple
                                    class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 text-900 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    :class="{ 'surface-ground text-900': activeTab === 3, 'hover:surface-hover': activeTab !== 3 }"
                                    @click="activeTab = 3"
                                >
                                    <i class="pi pi-cog mr-2"></i>
                                    <span>Settings</span>
                                </a>
                            </li>
                        </ul>
                        <ul class="list-none p-0 lg:pr-6 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-orange-600 hover:surface-hover font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                                    <span class="block lg:hidden font-medium">Inbox</span>
                                </a>
                            </li>
                            <li>
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-cyan-600 hover:surface-hover font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
                                    <span class="block lg:hidden font-medium">Notifications</span>
                                </a>
                            </li>
                            <li class="border-top-1 surface-border lg:border-top-none">
                                <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-hover font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                                    <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                                    <div class="block lg:hidden">
                                        <div class="text-900 font-medium">Josephine Lillard</div>
                                        <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="p-5 flex flex-column flex-auto surface-ground">
                    <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
                </div>
            </div>
        </BlockViewer>

        <BlockViewer header="Hover Borders" :code="block7" recent>
            <div class="min-h-screen flex flex-column surface-ground">
                <div class="surface-overlay px-6 flex justify-content-between relative lg:static" style="min-height: 80px">
                    <img src="images/blocks/logos/hyper.svg" alt="Image" height="40" class="mr-0 lg:mr-6 align-self-center" />
                    <a v-ripple class="cursor-pointer block lg:hidden text-700 align-self-center p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                        <i class="pi pi-bars text-4xl"></i>
                    </a>
                    <div class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
                        <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
                            <li>
                                <a
                                    v-ripple
                                    class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                >
                                    <i class="pi pi-home mr-2"></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li class="lg:relative">
                                <a
                                    v-ripple
                                    class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                    v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                >
                                    <i class="pi pi-users mr-2"></i>
                                    <span>Customers</span>
                                    <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                                </a>
                                <ul class="list-none py-0 px-6 m-0 lg:px-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                                    <li>
                                        <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-user-plus mr-2"></i>
                                            <span class="font-medium">Add New</span>
                                        </a>
                                    </li>
                                    <li class="relative">
                                        <a
                                            v-ripple
                                            class="flex h-full p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple"
                                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }"
                                        >
                                            <i class="pi pi-search mr-2"></i>
                                            <span class="font-medium">Search</span>
                                            <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                        </a>
                                        <ul class="list-none py-0 pl-3 m-0 lg:px-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                            <li>
                                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-shopping-cart mr-2"></i>
                                                    <span class="font-medium">Purchases</span>
                                                </a>
                                            </li>
                                            <li class="relative">
                                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple">
                                                    <i class="pi pi-comments mr-2"></i>
                                                    <span class="font-medium">Messages</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a
                                    v-ripple
                                    class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                >
                                    <i class="pi pi-comments mr-2"></i>
                                    <span>Messages</span>
                                    <badge value="3" class="ml-2"></badge>
                                </a>
                            </li>
                            <li>
                                <a
                                    v-ripple
                                    class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                >
                                    <i class="pi pi-cog mr-2"></i>
                                    <span>Settings</span>
                                </a>
                            </li>
                        </ul>
                        <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                            <li>
                                <a
                                    v-ripple
                                    class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                >
                                    <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
                                    <span class="block lg:hidden font-medium">Notifications</span>
                                </a>
                            </li>
                            <li class="border-top-1 surface-border lg:border-top-none">
                                <a
                                    v-ripple
                                    class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
                                >
                                    <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                                    <div class="block lg:hidden">
                                        <div class="text-900 font-medium">Josephine Lillard</div>
                                        <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
                    <li class="pr-3">
                        <a class="cursor-pointer">
                            <i class="pi pi-home text-blue-500"></i>
                        </a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
                    </li>
                    <li class="px-2">
                        <i class="pi pi-angle-right text-500"></i>
                    </li>
                    <li class="px-2">
                        <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
                    </li>
                </ul>
                <div class="p-5 flex flex-column flex-auto">
                    <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
                </div>
            </div>
        </BlockViewer>
    </div>
</template>

<script>
export default {
    name: 'StackLayout',
    data() {
        return {
            activeTab: 0,
            block1: `
<div class="min-h-screen flex flex-column surface-ground">
    <div class="surface-overlay py-3 px-6 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
        <img src="images/blocks/logos/bastion-700.svg" alt="Image" height="40" class="mr-0 lg:mr-6">
        <a v-ripple class="cursor-pointer block lg:hidden text-700 p-ripple"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-home mr-2"></i>
                        <span>Home</span>
                    </a>
                </li>
                <li class="lg:relative">
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                        <i class="pi pi-users mr-2"></i>
                        <span>Customers</span>
                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </a>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li>
                            <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <i class="pi pi-user-plus mr-2"></i>
                                <span class="font-medium">Add New</span>
                            </a>
                        </li>
                        <li class="relative">
                            <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                                <i class="pi pi-search mr-2"></i>
                                <span class="font-medium">Search</span>
                                <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                            </a>
                            <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                <li>
                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-shopping-cart mr-2"></i>
                                        <span class="font-medium">Purchases</span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-comments mr-2"></i>
                                        <span class="font-medium">Messages</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-calendar mr-2"></i>
                        <span>Calendar</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-chart-line mr-2"></i>
                        <span>Stats</span>
                    </a>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Inbox</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
                        <span class="block lg:hidden font-medium">Notifications</span>
                    </a>
                </li>
                <li class="border-top-1 surface-border lg:border-top-none">
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>
                        <div class="block lg:hidden">
                            <div class="text-900 font-medium">Josephine Lillard</div>
                            <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
        <li class="pr-3">
            <a class="cursor-pointer">
                <i class="pi pi-home text-blue-500"></i>
            </a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
        </li>
    </ul>
    <div class="p-5 flex flex-column flex-auto">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">

        </div>
    </div>
</div>`,
            block2: `
<div class="min-h-screen flex flex-column surface-ground">
    <div class="bg-gray-900 py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static border-bottom-1 border-gray-800" style="min-height: 84px">
        <img src="images/blocks/logos/bastion-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6">
        <a v-ripple class="cursor-pointer block lg:hidden text-gray-400 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-home mr-2"></i>
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                        <i class="pi pi-users mr-2"></i>
                        <span>Customers</span>
                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </a>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                        <li>
                            <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                <i class="pi pi-user-plus mr-2"></i>
                                <span class="font-medium">Add New</span>
                            </a>
                        </li>
                        <li class="relative">
                            <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple"
                                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                                <i class="pi pi-search mr-2"></i>
                                <span class="font-medium">Search</span>
                                <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                            </a>
                            <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800">
                                <li>
                                    <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-shopping-cart mr-2"></i>
                                        <span class="font-medium">Purchases</span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-comments mr-2"></i>
                                        <span class="font-medium">Messages</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-calendar mr-2"></i>
                        <span>Calendar</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-chart-line mr-2"></i>
                        <span>Stats</span>
                    </a>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li class="flex-order-2 lg:flex-order-0">
                    <div class="flex px-6 p-3 lg:px-3 lg:py-2">
                        <span class="p-input-icon-left w-full">
                            <i class="pi pi-search text-gray-400"></i>
                            <InputText type="text" placeholder="Search" class="bg-gray-800 text-gray-400 border-gray-800 w-full" />
                        </span>
                    </div>
                </li>
                <li class="border-top-1 border-gray-800 lg:border-top-none">
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>
                        <div class="block lg:hidden">
                            <div class="text-white font-medium">Josephine Lillard</div>
                            <span class="text-gray-400 font-medium text-sm">Marketing Specialist</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
        <li class="pr-3">
            <a class="cursor-pointer">
                <i class="pi pi-home text-blue-500"></i>
            </a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
        </li>
    </ul>
    <div class="p-5 flex flex-column flex-auto">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">

        </div>
    </div>
</div>`,
            block3: `
<div class="min-h-screen flex flex-column">
    <div class="bg-indigo-500 py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
        <img src="images/blocks/logos/hyper-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6">
        <a v-ripple class="cursor-pointer block lg:hidden text-indigo-50 p-ripple"
            v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-home mr-2"></i>
                        <span>Home</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                        v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                        <i class="pi pi-users mr-2"></i>
                        <span>Customers</span>
                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </a>
                    <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li>
                            <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                <i class="pi pi-user-plus mr-2"></i>
                                <span class="font-medium">Add New</span>
                            </a>
                        </li>
                        <li class="relative">
                            <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                                <i class="pi pi-search mr-2"></i>
                                <span class="font-medium">Search</span>
                                <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                            </a>
                            <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                <li>
                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-shopping-cart mr-2"></i>
                                        <span class="font-medium">Purchases</span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-comments mr-2"></i>
                                        <span class="font-medium">Messages</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-calendar mr-2"></i>
                        <span>Calendar</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-chart-line mr-2"></i>
                        <span>Stats</span>
                    </a>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Inbox</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Notifications</span>
                    </a>
                </li>
                <li class="border-top-1 border-indigo-400 lg:border-top-none">
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>
                        <div class="block lg:hidden">
                            <div class="text-indigo-50 font-medium">Josephine Lillard</div>
                            <span class="text-indigo-100 font-medium text-sm">Marketing Specialist</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border shadow-2 lg:shadow-none">
        <li class="pr-3">
            <a class="cursor-pointer">
                <i class="pi pi-home text-blue-500"></i>
            </a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
        </li>
    </ul>
    <div class="p-5 flex flex-column flex-auto">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">

        </div>
    </div>
</div>`,
            block4: `
<div class="min-h-screen flex flex-column">
    <div class="bg-indigo-500" style="height:250px">
        <div class="py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
            <img src="images/blocks/logos/hyper-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6">
            <a v-ripple class="cursor-pointer block lg:hidden text-indigo-50 p-ripple"
                v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-indigo-500 left-0 top-100 z-1">
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-home mr-2"></i>
                            <span>Home</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                            <i class="pi pi-users mr-2"></i>
                            <span>Customers</span>
                            <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                        </a>
                        <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                            <li>
                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-user-plus mr-2"></i>
                                    <span class="font-medium">Add New</span>
                                </a>
                            </li>
                            <li class="relative">
                                <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple"
                                    v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                                    <i class="pi pi-search mr-2"></i>
                                    <span class="font-medium">Search</span>
                                    <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                </a>
                                <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:border-1 border-50 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                    <li>
                                        <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-shopping-cart mr-2"></i>
                                            <span class="font-medium">Purchases</span>
                                        </a>
                                    </li>
                                    <li class="relative">
                                        <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 hover:surface-100 transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-comments mr-2"></i>
                                            <span class="font-medium">Messages</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-calendar mr-2"></i>
                            <span>Calendar</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-chart-line mr-2"></i>
                            <span>Stats</span>
                        </a>
                    </li>
                </ul>
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                            <span class="block lg:hidden font-medium">Inbox</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-indigo-100 hover:text-indigo-50 hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                            <span class="block lg:hidden font-medium">Notifications</span>
                        </a>
                    </li>
                    <li class="border-top-1 border-indigo-400 lg:border-top-none">
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-indigo-600 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>
                            <div class="block lg:hidden">
                                <div class="text-indigo-50 font-medium">Josephine Lillard</div>
                                <span class="text-indigo-100 font-medium text-sm">Marketing Specialist</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <ul class="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-indigo-600">
            <li class="pr-3">
                <a class="cursor-pointer">
                    <i class="pi pi-home text-indigo-200"></i>
                </a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-indigo-200"></i>
            </li>
            <li class="px-2">
                <a class="cursor-pointer text-indigo-200 white-space-nowrap">Level 3</a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-indigo-100"></i>
            </li>
            <li class="px-2">
                <a class="cursor-pointer text-indigo-200 white-space-nowrap">Level 2</a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-indigo-200"></i>
            </li>
            <li class="px-2">
                <a class="cursor-pointer text-indigo-100 white-space-nowrap">Level 1</a>
            </li>
        </ul>
    </div>
    <div class="p-5 flex flex-column flex-auto" style="margin-top: -8rem">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">

        </div>
    </div>
</div>`,
            block5: `
<div class="min-h-screen flex flex-column">
    <div class="bg-gray-900" style="height:250px">
        <div class="py-3 px-5 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
            <img src="images/blocks/logos/hyper-300.svg" alt="Image" height="40" class="mr-0 lg:mr-6">
            <a v-ripple class="cursor-pointer block lg:hidden text-gray-400 p-ripple"
                v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1">
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-home mr-2"></i>
                            <span>Home</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                            <i class="pi pi-users mr-2"></i>
                            <span>Customers</span>
                            <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                        </a>
                        <ul class="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">
                            <li>
                                <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                    <i class="pi pi-user-plus mr-2"></i>
                                    <span class="font-medium">Add New</span>
                                </a>
                            </li>
                            <li class="relative">
                                <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple"
                                    v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                                    <i class="pi pi-search mr-2"></i>
                                    <span class="font-medium">Search</span>
                                    <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                                </a>
                                <ul class="list-none py-3 pl-3 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0 lg:border-1 border-gray-800">
                                    <li>
                                        <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-shopping-cart mr-2"></i>
                                            <span class="font-medium">Purchases</span>
                                        </a>
                                    </li>
                                    <li class="relative">
                                        <a v-ripple class="flex p-3 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 transition-colors transition-duration-150 p-ripple">
                                            <i class="pi pi-comments mr-2"></i>
                                            <span class="font-medium">Messages</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-calendar mr-2"></i>
                            <span>Calendar</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-chart-line mr-2"></i>
                            <span>Stats</span>
                        </a>
                    </li>
                </ul>
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-gray-800 lg:border-top-none">
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                            <span class="block lg:hidden font-medium">Inbox</span>
                        </a>
                    </li>
                    <li>
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0"></i>
                            <span class="block lg:hidden font-medium">Notifications</span>
                        </a>
                    </li>
                    <li class="border-top-1 border-gray-800 lg:border-top-none">
                        <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px"/>
                            <div class="block lg:hidden">
                                <div class="text-white font-medium">Josephine Lillard</div>
                                <span class="text-gray-400 font-medium text-sm">Marketing Specialist</span>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <ul class="list-none py-3 px-5 m-0 flex align-items-center font-medium overflow-x-auto border-top-1 border-gray-800">
            <li class="pr-3">
                <a class="cursor-pointer">
                    <i class="pi pi-home text-gray-400"></i>
                </a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-gray-400"></i>
            </li>
            <li class="px-2">
                <a class="cursor-pointer text-gray-400 white-space-nowrap">Level 3</a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-gray-400"></i>
            </li>
            <li class="px-2">
                <a class="cursor-pointer text-gray-400 white-space-nowrap">Level 2</a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-gray-400"></i>
            </li>
            <li class="px-2">
                <a class="cursor-pointer text-indigo-300 white-space-nowrap">Level 1</a>
            </li>
        </ul>
    </div>
    <div class="p-5 flex flex-column flex-auto" style="margin-top: -8rem">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto">

        </div>
    </div>
</div>`,
            block6: `
<div class="min-h-screen flex flex-column surface-ground">
    <div class="surface-section px-6 flex justify-content-between relative lg:static" style="min-height: 70px">
        <img src="images/blocks/logos/hyper-700.svg" alt="Image" height="40" class="mr-0 lg:mr-6 align-self-center" />
        <a v-ripple class="cursor-pointer block lg:hidden align-self-center text-700" v-styleclass="{ selector: '@next', enterClass: 'hiddenleaveToClass: 'hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-nosurface-overlay">
            <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row gap-2">
                <li class="lg:flex lg:align-items-end">
                    <a v-ripple class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 font-medium cursor-pointtransition-colors transition-duration-150" :class="{ 'surface-ground text-900': activeTab === 0, 'hover:surface-hover': activeT!== 0 }" @click="activeTab = 0">
                        <i class="pi pi-home mr-2"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li class="lg:flex lg:align-items-end">
                    <a v-ripple class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 text-900 font-medium cursor-pointtransition-colors transition-duration-150" :class="{ 'surface-ground text-900': activeTab === 1, 'hover:surface-hover': activeT!== 1 }" @click="activeTab = 1">
                        <i class="pi pi-chart-line mr-2"></i>
                        <span>Reports</span>
                    </a>
                </li>
                <li class="lg:flex lg:align-items-end">
                    <a v-ripple class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 text-900 font-medium cursor-pointtransition-colors transition-duration-150" :class="{ 'surface-ground text-900': activeTab === 2, 'hover:surface-hover': activeT!== 2 }" @click="activeTab = 2">
                        <i class="pi pi-users mr-2"></i>
                        <span class="mr-2">Team</span>
                    </a>
                </li>
                <li class="lg:flex lg:align-items-end">
                    <a v-ripple class="border-round-top flex align-items-center px-6 py-3 lg:px-3 lg:py-3 text-900 font-medium cursor-pointtransition-colors transition-duration-150" :class="{ 'surface-ground text-900': activeTab === 3, 'hover:surface-hover': activeT!== 3 }" @click="activeTab = 3">
                        <i class="pi pi-cog mr-2"></i>
                        <span>Settings</span>
                    </a>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-bordlg:border-top-none">
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-orange-600 hover:surface-hover font-medium border-roucursor-pointer transition-colors transition-duration-150">
                        <i class="pi pi-inbox text-base lg:text-2xl mr-2 lg:mr-0"></i>
                        <span class="block lg:hidden font-medium">Inbox</span>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-cyan-600 hover:surface-hover font-medium border-roucursor-pointer transition-colors transition-duration-150">
                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
                        <span class="block lg:hidden font-medium">Notifications</span>
                    </a>
                </li>
                <li class="border-top-1 surface-border lg:border-top-none">
                    <a v-ripple class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center hover:surface-hover font-medium border-round cursor-pointtransition-colors transition-duration-150">
                        <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                        <div class="block lg:hidden">
                            <div class="text-900 font-medium">Josephine Lillard</div>
                            <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <div class="p-5 flex flex-column flex-auto surface-ground">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
    </div>
</div>`,

            block7: `
<div class="min-h-screen flex flex-column surface-ground">
    <div class="surface-overlay px-6 flex justify-content-between relative lg:static" style="min-height: 80px">
        <img src="images/blocks/logos/hyper.svg" alt="Image" height="40" class="mr-0 lg:mr-6 align-self-center" />
        <a v-ripple class="cursor-pointer block lg:hidden text-700 align-self-center" v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
                <li>
                    <a v-ripple class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-home mr-2"></i>
                        <span>Dashboard</span>
                    </a>
                </li>
                <li class="lg:relative">
                    <a v-ripple class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                        <i class="pi pi-users mr-2"></i>
                        <span>Customers</span>
                        <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                    </a>
                    <ul class="list-none py-0 px-6 m-0 lg:px-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer">
                        <li>
                            <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple">
                                <i class="pi pi-user-plus mr-2"></i>
                                <span class="font-medium">Add New</span>
                            </a>
                        </li>
                        <li class="relative">
                            <a v-ripple class="flex h-full p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple" v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                                <i class="pi pi-search mr-2"></i>
                                <span class="font-medium">Search</span>
                                <i class="pi pi-angle-down ml-auto lg:-rotate-90"></i>
                            </a>
                            <ul class="list-none py-0 pl-3 m-0 lg:px-0 border-round shadow-0 lg:shadow-2 lg:border-1 border-50 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem cursor-pointer left-100 top-0">
                                <li>
                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-shopping-cart mr-2"></i>
                                        <span class="font-medium">Purchases</span>
                                    </a>
                                </li>
                                <li class="relative">
                                    <a v-ripple class="flex p-3 align-items-center text-600 hover:text-900 border-left-2 border-transparent hover:border-primary transition-colors transition-duration-150 p-ripple">
                                        <i class="pi pi-comments mr-2"></i>
                                        <span class="font-medium">Messages</span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li>
                    <a v-ripple class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-comments mr-2"></i>
                        <span>Messages</span>
                        <badge value="3" class="ml-2"></badge>
                    </a>
                </li>
                <li>
                    <a v-ripple class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-cog mr-2"></i>
                        <span>Settings</span>
                    </a>
                </li>
            </ul>
            <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
                <li>
                    <a v-ripple class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <i class="pi pi-bell text-base lg:text-2xl mr-2 lg:mr-0" v-badge.danger></i>
                        <span class="block lg:hidden font-medium">Notifications</span>
                    </a>
                </li>
                <li class="border-top-1 surface-border lg:border-top-none">
                    <a v-ripple class="flex h-full px-6 p-3 lg:px-3 lg:py-2 align-items-center border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-primary font-medium cursor-pointer transition-colors transition-duration-150 p-ripple">
                        <img src="images/blocks/avatars/circle/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 32px; height: 32px" />
                        <div class="block lg:hidden">
                            <div class="text-900 font-medium">Josephine Lillard</div>
                            <span class="text-600 font-medium text-sm">Marketing Specialist</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
    <ul class="list-none py-3 px-5 m-0 surface-section flex align-items-center font-medium overflow-x-auto border-top-1 border-bottom-1 surface-border">
        <li class="pr-3">
            <a class="cursor-pointer">
                <i class="pi pi-home text-blue-500"></i>
            </a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 3</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-blue-500 white-space-nowrap">Level 2</a>
        </li>
        <li class="px-2">
            <i class="pi pi-angle-right text-500"></i>
        </li>
        <li class="px-2">
            <a class="cursor-pointer text-900 white-space-nowrap">Level 1</a>
        </li>
    </ul>
    <div class="p-5 flex flex-column flex-auto">
        <div class="border-2 border-dashed surface-border border-round surface-section flex-auto"></div>
    </div>
</div>`
        };
    }
};
</script>
